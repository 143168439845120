import { createBrowserRouter } from "react-router-dom";
import { Home } from "@/pages/home";
import { Login } from "@/pages/login";
import { Registration } from "@/pages/registration";
import { LoginConfirmPhone } from "@/pages/login-confirm-phone";
import { Root } from "@/pages/root";
import { Welcome } from "@/pages/welcome";
import { Places } from "@/pages/places";
import { PlaceInfo, PlacesList } from "@/widgets/places";
import { Tours } from "@/pages/tours";
import { TourInfo, ToursList } from "@/widgets/tours";
import { Events } from "@/pages/events";
import { EventInfo, EventsList } from "@/widgets/events";
import { Profile } from "@/pages/profile";
import { ProfileDetails } from "@/features/update-profile";
import { Excursion } from "@/pages/excursions";
import { ExcursionsList } from "@/widgets/excursions";
import { ExcursionInfo } from "@/widgets/excursions";
import { UserTickets } from "@/widgets/account";
import { FavoritePlaces } from "@/widgets/account";
import { Articles } from "@/pages/articles";
import { ArticleInfo, ArticlesList } from "@/widgets/articles";
import { Guides } from "@/pages/guides";
import { GuideDetails, GuidesList } from "@/entities/guides";
import { PrivacyPolicy } from "@/pages/privacy-policy";
import { MyGuide } from "@/pages/my-guide";
import { UseConditions } from "@/pages/use-conditions";
import { NotFoundPage } from "@/pages/not-found-page";
import { RegistrationConfirmPhone } from "@/pages/registration-confirm-phone";
import { Organizations } from "@/pages/organizations";
import {
  OrganizationDetails,
  OrganizationsList,
} from "@/entities/organizations";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "*",
        element: <NotFoundPage />,
      },
      { index: true, element: <Home /> },
      { path: "home", element: <Home /> },
      { path: "welcome", element: <Welcome /> },
      { path: "policy", element: <PrivacyPolicy /> },
      { path: "use-conditions", element: <UseConditions /> },

      {
        path: "guides",
        element: <Guides />,
        children: [
          {
            index: true,
            element: <GuidesList />,
          },
          {
            path: ":id",
            element: <GuideDetails />,
            handle: {
              crumb: [
                { path: "/", title: "Главная" },
                { path: "/guides", title: "Гиды" },
              ],
            },
          },
        ],
      },
      {
        path: "organizations",
        element: <Organizations />,
        children: [
          {
            index: true,
            element: <OrganizationsList />,
          },
          {
            path: ":id",
            element: <OrganizationDetails />,
            handle: {
              crumb: [
                { path: "/", title: "Главная" },
                { path: "/organizations", title: "Организации" },
              ],
            },
          },
        ],
      },
      {
        path: "articles",
        element: <Articles />,
        children: [
          { index: true, element: <ArticlesList /> },
          {
            path: ":id",
            element: <ArticleInfo />,
            handle: {
              crumb: [
                { path: "/", title: "Главная" },
                { path: "/articles", title: "Новости" },
              ],
            },
          },
        ],
      },
      {
        path: "tours",
        element: <Tours />,
        children: [
          { index: true, element: <ToursList /> },
          {
            path: ":id",
            element: <TourInfo />,
            handle: {
              crumb: [
                { path: "/", title: "Главная" },
                { path: "/tours", title: "Туры" },
              ],
            },
          },
        ],
      },
      {
        path: "excursions",
        element: <Excursion />,
        children: [
          { index: true, element: <ExcursionsList /> },
          {
            path: ":id",
            element: <ExcursionInfo />,
            handle: {
              crumb: [
                { path: "/", title: "Главная" },
                { path: "/excursions", title: "Экскурсии" },
              ],
            },
          },
        ],
      },
      {
        path: "events",
        element: <Events />,
        children: [
          { index: true, element: <EventsList /> },
          {
            path: ":id",
            element: <EventInfo />,
            handle: {
              crumb: [
                { path: "/", title: "Главная" },
                { path: "/events", title: "События" },
              ],
            },
          },
        ],
      },
      {
        path: "places",
        element: <Places />,
        children: [
          {
            path: "list",
            children: [
              {
                path: ":category",
                element: <PlacesList />,
              },
            ],
          },
          {
            path: "slug",
            children: [{ path: ":slug", element: <PlaceInfo /> }],
          },
          {
            path: ":id",
            element: <PlaceInfo />,
          },
        ],
      },
      {
        path: "profile",
        element: <Profile />,
        children: [
          {
            index: true,
            element: <ProfileDetails />,
          },
          {
            path: "me",
            element: <ProfileDetails />,
          },
          {
            path: "tickets",
            element: <UserTickets />,
          },
          {
            path: "favorites",
            element: <FavoritePlaces />,
          },
        ],
      },
    ],
  },
  {
    path: "/my-guide",
    element: <MyGuide />,
  },
  {
    path: "/auth",
    element: <Root isAuthCheck={true} />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "registration",
        element: <Registration />,
      },
      {
        path: "login-confirm",
        element: <LoginConfirmPhone />,
      },
      {
        path: "registration-confirm",
        element: <RegistrationConfirmPhone />,
      },
    ],
  },
]);
