import { useGetStaticQuery } from "@/entities/static";
import { Container } from "@/shared/ui";
import { Loading } from "@/shared/ui/loading";
import { Link } from "react-router-dom";
import { DownloadAppModal } from "@/features/download-app";

export const Footer: React.FC = () => {
  const { data: contacts, isLoading } = useGetStaticQuery("");

  return (
    <footer className="hidden pt-4 pb-20 text-white lg:pb-4 mt:-auto footer bg-base-300 lg:block">
      <Container direction="col">
        <div className="flex flex-col w-full gap-5 sm:flex-row">
          <div className="w-[50%]">
            <Link
              to={"/home#plates"}
              className="text-xl text-white uppercase navbar-start kalmyk-font"
            >
              ЭЛИСТА
            </Link>
          </div>
          <div className="flex flex-wrap gap-5 md:gap-10 lg:gap-20 lg:shrink-0">
            <nav className="flex flex-col gap-2">
              <h6 className="mb-2 text-white">Проекты</h6>
              <Link to={"/guides"} className="text-gray-400 link link-hover">
                Услуги гидов
              </Link>
            </nav>
            <nav className="flex flex-col gap-2">
              <h6 className="mb-2 text-white">Пользователи</h6>
              <Link to={"/policy"} className="text-gray-400 link link-hover">
                Политика конфиденциальности
              </Link>
              <Link
                to={"/use-conditions"}
                className="text-gray-400 link link-hover"
              >
                Условия использования
              </Link>
              <Link
                to={"/home#plates"}
                className="text-gray-400 link link-hover"
              >
                Удалить аккаунт
              </Link>
            </nav>
            <div className="flex flex-col gap-2">
              <h6 className="mb-2 text-white">Организация</h6>
              <p className="text-gray-400 ">ИП Муниев А.М.</p>
              <p className="text-gray-400 ">ИНН: 081407563384</p>
              <p className="text-gray-400 ">ОГРНИП: 319081600009565A</p>
              <p className="text-gray-400 ">
                Почтовый адрес: 358000, республика Калмыкия, ул. Клыкова, 1а
              </p>
            </div>
          </div>
          <div className="w-[50%] hidden lg:block text-end">
            <DownloadAppModal />
          </div>
        </div>
        <div className="flex flex-wrap justify-between w-full mt-4">
          {isLoading ? (
            <Loading size="xs" />
          ) : (
            <div className="flex flex-col gap-1">
              <a href={`tel:${contacts?.phone}`} className="fb hover:underline">
                {contacts?.phone}
              </a>
              <a
                href={`mailto:${contacts?.email}`}
                className="fb hover:underline"
              >
                {contacts?.email}
              </a>
            </div>
          )}

          <p className="fb">© 2024 Все права защищены</p>
        </div>
      </Container>
    </footer>
  );
};
